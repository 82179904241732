/**
 * @flow
 */
import React from 'react';
import PageContainer from './PageContainer';
import CustomerInputForm from '../../forms/CustomerInputForm';
import {api, util} from '../../services/service';
import {useHistory, useLocation} from 'react-router-dom';
import {basePathRegex, dropzoneIdRegex} from './ServiceSelector';

const customerInputForm = new CustomerInputForm(true);

const CustomerForm = () => {
  const location = useLocation();
  const history = useHistory();
  const basePath = location.pathname.match(basePathRegex)?.[0] ?? '/';
  const dropzoneId = basePath.match(dropzoneIdRegex)?.[1] ?? 0;
  customerInputForm.dropzoneId = dropzoneId;
  customerInputForm.onSave = async (data) => {
    const res = await api.saveBLData(dropzoneId, data);
    if (res) {
      util.showSuccess('Request has been saved successfully!');
      customerInputForm.clearValuesFast();
      history.replace(`${basePath}/select`);
    }
  };
  const {d: destination = 'KR'} = util.getQS();
  customerInputForm.destination = destination;
  React.useEffect(() => {
    const {s} = util.getQS();
    customerInputForm.setValue('serviceType', s);
  }, []);
  return (
    <PageContainer>
      {customerInputForm.renderForm()}
    </PageContainer>
  );
};

export default CustomerForm;
