/**
 * @flow
 */
import React from 'react';
import {Modal} from 'reactstrap';
import {ColDef} from 'ag-grid-community';
import type {FormField} from './Form';
import DataGrid, {ActionCell, queryGridData} from './DataGrid';
import type {DataGridActionType, DataGridProps, DataGridQuery} from './DataGrid';
import Form from './Form';
import {util} from '../services/service';
import {useConfirm} from './Alert';
import type {DataGridInfo} from '../redux/reducers/dataGridReducer';
import {updateDataGridInfo} from '../redux/reducers/dataGridReducer';
import {AgGridReactProps} from 'ag-grid-react';
// import {useTopbar} from '../redux/reducers/topbarReducer';

export type DataGridViewAction = (action: DataGridActionType | 'addOpen' | 'editOpen' | 'modalClose', data: Object) => Promise<any>;

export interface ModalState {
  open: boolean;
  mode?: 'add' | 'edit';
  data?: Object;
}

export interface DataGridViewProps {
  name: string;
  label: string;
  sortCol: string;
  sortDesc: boolean;
  pageRows: number;
  addLabel: string;
  editLabel: string;
  categoryLabel: string;
  menuLabel: string;
  columns: (ColDef | FormField)[] | (state: ModalState) => (ColDef | FormField)[];
  actions: DataGridActionType[] | () => DataGridActionType[];
  actionWidth: number;
  nameMap?: Object;
  fields?: FormField[] | (state: ModalState) => FormField[];
  dateFields?: string[];
  renderForm?: (formik: any, fields: Array, errors: Array) => React$Node;
  showDateSearch: boolean;
  onAction: DataGridViewAction;
  onQuery: DataGridQuery,
  modalWidth?: number,
  isActionDisabled?: (type: DataGridActionType, data: Object) => boolean;
  useExtendedColDef?: boolean;
  onFormik?: (formik: any) => void;
  showDeleteButton?: boolean;
  onRowClick?: (data: Object, rowIndex: number, param: Object) => void;
  onCellClick?: (event: Object) => void;
  onCellValueChange?: (e: any) => void;
  hideSearchInput?: boolean;
  sortableColumns?: Object;
  useExternalForm?: boolean; // if true, onAction('add' | 'edit'); will be called when click add button or edit icon
  initialSearchToday?: boolean;
  initialSearchOneWeek?: boolean;
  gridRef?: any;
  agGridProps?: AgGridReactProps;
}

const DataGridView = (props: DataGridViewProps) => {
  const [modal, setModal] = React.useState<ModalState>({open: false});
  const confirm = useConfirm();
  // useTopbar(props.categoryLabel, props.menuLabel);
  const onEditClick = (mode, data) => {
    if (props.useExternalForm === true) {
      props.onAction(mode, data);
    } else {
      setModal({open: true, mode, data});
    }
  }
  const gridProps = getDataGridProps(props, onEditClick, confirm, modal);
  React.useEffect(() => {
    if (modal.open === true) {
      props.onAction(modal.mode === 'add' ? 'addOpen' : 'editOpen', modal.data);
    } else {
      props.onAction('modalClose', undefined);
    }
  }, [modal.open]);
  const fields = (props.useExtendedColDef === true ? gridProps.columns : (typeof props.fields === 'function' ? props.fields(modal) : props.fields)) ?? [];
  const onDelete = (row) => {
    confirm.show('Confirm', 'Are you sure to delete?', async () => {
      const res = await props.onAction('delete', row);
      if (res) {
        util.showSuccess(`${props.label} has been deleted successfully!`);
        await queryGridData(props.name, undefined, props.onQuery);
        setModal({open: false});
      }
    }, 'warning');
  };
  return (
    <>
      <DataGrid {...gridProps} />
      {props.renderForm && (
        <Modal isOpen={modal.open} className={'modal-dialog--form'} centered style={{width: props.modalWidth ?? 540}}>
          <Form
            onFormik={props.onFormik}
            title={modal.mode === 'add' ? props.addLabel : props.editLabel}
            horizontal
            fields={fields}
            values={modal.data}
            onSubmit={async values => {
              //console.log('hello', 'start');
              const res = await props.onAction(modal.mode, values);
              //console.log('hello', 'end');
              if (res) {
                util.showSuccess(`${props.label} has been ${modal.mode === 'add' ? 'added' : 'updated'} successfully!`);
                await queryGridData(props.name, undefined, props.onQuery);
                setModal({open: false});
              } else {
                util.showError();
              }
            }}
            onDelete={props.showDeleteButton === true ? onDelete : undefined}
            onCancel={() => setModal({open: false})}
            render={(formik, fields, errors) => props.renderForm(formik, fields, errors)}
           />
        </Modal>
      )}
      {confirm.render()}
    </>
  );
};

function getEditData(row: Object, props: DataGridViewProps, modal: ModalState) {
  if (props.useExternalForm === true) {
    return row;
  }
  if (props.useExtendedColDef === true) {
    const values = {};
    const columns = typeof props.columns === 'function' ? props.columns(modal) : props.columns;
    for (const column of columns) {
      if (column.type === 'date') {
        values[column.name] = util.formatDate(row[column.field], 'YYYY-MM-DD');
      } else {
        values[column.name] = row[column.field];
      }
    }
    return values;
  } else {
    return util.valuesFromListData(row, typeof props.fields === 'function' ? props.fields(modal) : props.fields, props.nameMap, props.dateFields)
  }
}

function createActionCell(props: DataGridViewProps, onEditClick, confirm, modal: ModalState) {
  const actions = typeof props.actions === 'function' ? props.actions() : props.actions;
  if (actions && actions.length > 0) {
    const cellRendererParams = {isDisabled: props.isActionDisabled};
    for (const action of actions) {
      switch (action) {
        case 'edit':
          cellRendererParams['onEditClick'] = row => {
            onEditClick(
              'edit',
              getEditData(row, props, modal),
            );
          };
          break;
        case 'delete':
          cellRendererParams['onDeleteClick'] = row => {
            confirm.show('Confirm', 'Are you sure to delete?', async () => {
              const res = await props.onAction('delete', row);
              if (res) {
                util.showSuccess(`${props.label} has been deleted successfully!`);
                await queryGridData(props.name, undefined, props.onQuery);
              }
            }, 'warning');
          };
          break;
        case 'detail':
          cellRendererParams['onDetailClick'] = row => props.onAction('detail', row);
          break;
        case 'addChild':
          cellRendererParams['onAddChildClick'] = row => props.onAction('addChild', row);
          break;
        case 'history':
          cellRendererParams['onHistoryClick'] = row => props.onAction('history', row);
          break;
        case 'print':
          cellRendererParams['onPrintClick'] = row => props.onAction('print', row);
          break;
        case 'invoice':
          cellRendererParams['onInvoiceClick'] = row => props.onAction('invoice', row);
          break;
        case 'commission':
          cellRendererParams['onCommissionClick'] = row => props.onAction('commission', row);
          break;
      }
    }
    return {
      headerName: 'Actions',
      width: props.actionWidth,
      minWidth: props.actionWidth,
      cellRendererFramework: ActionCell,
      cellRendererParams,
    };
  }
}

function getDataGridProps(props: DataGridViewProps, onEditClick, confirm, modal: ModalState): DataGridProps {
  const {
    name, sortCol, sortDesc, pageRows,
    addLabel,
    columns,
    showDateSearch,
    onQuery,
    onRowClick,
    onCellClick,
    onCellValueChange,
    hideSearchInput,
    sortableColumns,
    initialSearchToday,
    initialSearchOneWeek,
    gridRef,
    agGridProps,
  } = props;
  const cols = typeof columns === 'function' ? columns(modal) : columns;
  const actionCol = createActionCell(props, onEditClick, confirm, modal);
  return {
    name,
    columns: actionCol ? [actionCol, ...cols] : cols,
    gridInfo: {
      qryText: '',
      columns,
      orderBy: sortCol,
      isDesc: sortDesc,
      rowCount: pageRows,
    },
    leftButton: addLabel ? {label: addLabel, onClick: () => onEditClick('add')} : undefined,
    showDateSearch,
    onQuery,
    onRowClick,
    onCellClick,
    onCellValueChange,
    hideSearchInput,
    sortableColumns,
    initialSearchToday,
    initialSearchOneWeek,
    gridRef,
    agGridProps,
  };
}

function getDataGridInfo(props: DataGridViewProps) {
  return {
    qryText: '',
    orderBy: props.sortCol,
    isDesc: props.sortDesc,
    rowCount: props.pageRows,
  };
}

export interface UseDataGridView {
  props: DataGridViewProps;
  query: (gridInfo?: DataGridInfo) => Promise<any>;
  render: () => React$Node;
}

export function useDataGridView({onAction, onQuery, ...props}: DataGridViewProps): UseDataGridView {
  const actionHandler = (action, data) => {
    process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${props.label} action`, action, data);
    return onAction(action, data);
  };
  const queryHandler = (gridInfo) => {
    process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${props.label} query`, gridInfo);
    return onQuery(gridInfo);
  }
  const gridViewProps = {...props, onAction: actionHandler, onQuery: queryHandler};
  React.useEffect(() => {
    return () => {
      updateDataGridInfo(util.dispatch, gridViewProps.name, getDataGridInfo(props)); // 컴퍼넌트가 언마운트 될때 그리드의 쿼리 정보를 기본 값으로 리셋함!
    };
  }, []);
  return {
    props: gridViewProps,
    query: gridInfo => queryGridData(gridViewProps.name, gridInfo, gridViewProps.onQuery),
    render: () => <DataGridView {...gridViewProps} />
  };
}

export default DataGridView;
