/**
 * @flow
 */
import API from './API';
import Util from './Util';

export const api = new API();
export const util = new Util();

const BASE_PATH = '';
export const pathNames = {
  BASE_PATH,
  HOME: `${BASE_PATH}/`,
  LOGIN: `${BASE_PATH}/login`,
  FORM: `${BASE_PATH}/form`,
  TABLET_SELECT: `${BASE_PATH}/tablet/:id/select`,
  TABLET_FORM: `${BASE_PATH}/tablet/:id/form`,
};
