/**
 * @flow
 */
import React from 'react';
import axios from 'axios';
import {resetUser, updateUser} from '../redux/reducers/userReducer';
import {showDialog} from '../redux/reducers/globalDialogReducer';
import {util} from './service';

const useDev = window.location.host === 'localhost:3000' || window.location.host === 'vendor.homekling.com';
const API_URL_DEV = 'http://coship.homekling.com';
const API_URL_PRD = useDev ? API_URL_DEV : 'https://adm.coship.ca';

const API_URL = process.env.NODE_ENV === 'production' ? API_URL_PRD : API_URL_DEV;
const LOGIN_PATH = '/login';

class API {
  token;
  dispatch;
  history;
  async login(data) {
    const action = 'ReqLoginVendor';
    const timeDelta = new Date().getTimezoneOffset();
    const reqData = {action, data: {...data, timeDelta}};
    const {data: [user] = []} = await this.sendList('vendor/login/LOGIN', reqData);
    if (user) {
      this.token = user.token;
      localStorage.setItem('@token', user.token);
      // localStorage.setItem('@user', JSON.stringify(res[0]));
      updateUser(this.dispatch, user);
      return user;
    } else {
      return undefined;
    }
  }
  async queryUser() {
    this.token = localStorage.getItem('@token');
    const {data} = await this.sendList('vendor/GetData', {action: 'GetLoginData', data: {}});
    const [user] = data ?? [];
    if (user) {
      updateUser(this.dispatch, user);
    }
    return user;
  }
  async queryGuestToken(dropZoneId) {
    const reqData = {
      action: 'ReqDummyToken',
      data: {
        dropZoneId: parseInt(dropZoneId),
        timeDelta: new Date().getTimezoneOffset(),
      }
    };
    const {data} = await this.sendList('vendor/login/LOGIN', reqData);
    if (data?.[0]?.token) {
      this.token = data[0].token;
      return true;
    } else {
      return false;
    }
  }
  async logout(path) {
    this.token = undefined;
    localStorage.removeItem('@token');
    // localStorage.removeItem('@user');
    resetUser(this.dispatch);
    util.nav('/login');
    if (path) util.nav(path);
  }
  async getFreightPrice({portType, grossWeightKg, length, width, height, packageCount, isDocument}) {
    const reqData = {
      action: 'GetFreightPrice',
      data: {portType, grossWeightKg, length, width, height, package: packageCount, isDocument}
    };
    return this.sendList('vendor/GetData', reqData);
  }
  async getBLList(queryData) {
    const data = this.getQueryListData(queryData);
    const action = 'ListManifest';
    const path = 'vendor/ListData';
    return this.sendList(path, {action, data});
  }
  async getBLHistoryList(queryData) {
    const data = this.getQueryListData(queryData);
    const action = 'ListBLHistory';
    const path = 'vendor/ListData';
    return this.sendList(path, {action, data});
  }
  async getBLData(blId) {
    const data = {blId};
    const action = 'TradeBLData';
    const path = 'vendor/ViewData';
    return this.sendList(path, {action, data});
  }
  async saveBLData(dropzoneId, {blId, blNo, jcommon, jcustomer, jshipment}) {
    if (dropzoneId) {
      // 드랍존 아이디가 패러미터로 넘어 오면 사용자 입력이므로 반드시 토큰을 쿼리해야함!
      // 드랍존 관리자인 경우 로그인시 이미 토큰이 존재하므로 토큰을 쿼리할 필요 없음!
      await this.queryGuestToken(dropzoneId);
    }
    const reqData = {
      action: 'SetBLEntry',
      data: {blId, blNo, jCommon: jcommon, jCustomer: jcustomer, jShipment: jshipment},
    };
    return this.sendCUD('vendor/CUD', reqData);
  }
  async uploadCSV(data) {
    const parts = data.split(',');
    return this.sendBase64('vendor/CSVUP', parts[1]);
  }
  async delVendorBL(blList) {
    const reqData = {
      action: 'DelVendorBL',
      data: {blList},
    };
    return this.sendCUD('vendor/CUD', reqData);
  }
  async setBLPrint(blNoList) {
    return this.sendCUD('vendor/CUD', {action: 'SetPrintFlag', data: {blNos: blNoList}});
  }
  async sendList(path, reqData, doNotShowError) {
    const res = await this.send(path, reqData, doNotShowError);
    return res ?? {data: null, totalCount: 0};
  }
  async sendCUD(path, reqData, doNotShowError) {
    const res = await this.send(path, reqData, doNotShowError);
    if (res?.data?.[0].ret === true) {
      return true;
    } else if (res?.data?.[0]) {
      return res.data[0];
    } else {
      return false;
    }
  }
  async send(path, reqData, doNotShowError) {
    try {
      //process.env.NODE_ENV !== 'production' && console.log('[API] REQ', path, reqData);
      const res = await axios.request({
        url: `${API_URL}/${path}`,
        method: 'POST',
        data: reqData,
        headers: {
          Authorization: `bearer ${this.getToken()}`,
        }
      });
      process.env.NODE_ENV !== 'production' && console.log('[API]', path, reqData, res);
      const {err_message: message, data, totalcount: totalCount = 0} = res.data;
      if (!message) {
        return {data, totalCount};
      } else {
        const msg = message.toLowerCase();
        if (msg === 'login') {
          this.history.push(LOGIN_PATH);
        } else if (msg.startsWith('error')) {
          if (doNotShowError !== true) {
            const messages = msg.split('#');
            if (messages.length === 2) {
              showDialog(`Something went wrong! (${messages[1]})`, 'System Error', 'danger')
            } else {
              showDialog('Something went wrong!', 'System Error', 'danger')
            }
          }
        } else {
          //console.log('hello', message, doNotShowError);
          if (doNotShowError !== true) {
            showDialog(message, 'System Error', 'danger')
          }
        }
      }
    } catch (error) {
      if (error?.isAxiosError && error?.response?.status === 401) {
        this.history.push(LOGIN_PATH);
      } else {
        if (doNotShowError !== true) {
          showDialog('Something went wrong!', 'System Error', 'danger')
        }
      }
    }
  }
  async sendBase64(path, reqData, doNotShowError) {
    try {
      //process.env.NODE_ENV !== 'production' && console.log('[API] REQ', path, data);
      const res = await axios.request({
        url: `${API_URL}/${path}`,
        method: 'POST',
        data: reqData,
        headers: {
          ContentType: 'plain/text',
          Authorization: `bearer ${this.getToken()}`,
        }
      });
      process.env.NODE_ENV !== 'production' && console.log('[API]', path, reqData, res);
      const {err_message: message, data, totalcount: totalCount = 0} = res.data;
      if (!message) {
        return {data, totalCount};
      } else {
        const msg = message.toLowerCase();
        if (msg === 'login') {
          this.history.push(LOGIN_PATH);
        } else if (msg.startsWith('error')) {
          if (doNotShowError !== true) {
            const messages = msg.split('#');
            if (messages.length === 2) {
              showDialog(`Something went wrong! (${messages[1]})`, 'System Error', 'danger')
            } else {
              showDialog('Something went wrong!', 'System Error', 'danger')
            }
          }
        } else {
          if (doNotShowError !== true) {
            showDialog(message, 'System Error', 'danger')
          }
        }
      }
    } catch (error) {
      if (error?.isAxiosError && error?.response?.status === 401) {
        this.history.push(LOGIN_PATH);
      } else {
        if (doNotShowError !== true) {
          showDialog('Something went wrong!', 'System Error', 'danger')
        }
      }
    }
  }
  getToken() {
    return this.token ?? localStorage.getItem('@token');
  }
  getQueryListData(data) {
    const {
      page,
      rowCount,
      qryText,
      orderBy,
      isDesc,
      fromDate,
      toDate,
      portType,
    } = data;
    return {
      page: page ?? 1,
      rowCount: rowCount ?? 20,
      qryText,
      orderBy,
      isDesc,
      fromDate,
      toDate,
      portType,
    };
  }
}

export default API;
