/**
 * @flow
 */
import React from 'react';
import PageContainer from './PageContainer';
import {useDataGridView} from '../../components/DataGridView';
import {useUser} from '../../redux/reducers/userReducer';
import {api, pathNames, util} from '../../services/service';
import {DefaultButton} from '../../components/buttons';
import {Container} from 'reactstrap';
import {ColDef} from 'ag-grid-community';
import {getColumns} from './AdminList';
// import {useParams} from 'react-router-dom';

const AdminHistoryList = () => {
  const gridRef = React.useRef();
  const user = useUser();
  // const printModalRef = React.useRef();
  // const printModal = useManifestPreviewModal();
  // printModalRef.current = printModal;
  const grid = useGrid(user, gridRef, (params) => {gridRef.current = params.api});
  const onDownload = () => {
    if (gridRef.current) {
      gridRef.current.exportDataAsCsv({
        fileName: `vendor-history-${util.formatD(Date.now())}.csv`,
        columnKeys: grid.props.columns.map(c => c.field),
      });
    }
  };
  const onBack = () => {
    util.nav('/');
  };
  // const onPrint = () => {
  //   const grid = gridRef.current;
  //   const selectedRows = grid.getSelectedRows();
  //   if (selectedRows.length > 0) {
  //     printModal.open(selectedRows);
  //   } else {
  //     util.showWarning('Please select rows to print.');
  //   }
  // };
  // const isVendor = user.partner_type === 'VD';
  return (
    <PageContainer>
      <Container style={{position: 'relative'}}>
        {user.id > 0 && grid.render()}
        {user.id > 0 && (
          <div className={'flex'} style={{position: 'absolute', left: 20, top: 10}}>
            <DefaultButton label={'Back'} onClick={onBack} noMargin />
            <div className={'ml-8'} />
            <DefaultButton label={'Export Excel'} onClick={onDownload} noMargin />
          </div>
        )}
      </Container>
      {/*{printModal.render()}*/}
    </PageContainer>
  );
};

function useGrid(user, gridRef) {
  const isVendor = user.partner_type === 'VD';
  const columns = getColumns(isVendor, true);
  return useDataGridView({
    onAction: (action, data) => {
      if (action === 'edit') {
        util.nav(pathNames.FORM + `?id=${data['bl_id']}`);
      } else if (action === 'print') {
        // printModalRef.current.open(data);
      }
    },
    onQuery: async (listParams) => {
      if (user.id > 0) {
        const {data, totalCount} = await api.getBLHistoryList({...listParams, partnerId: user.id});
        return {
          totalCount,
          data: data.map(row => ({
            ...row,
            post_date: util.formatD(row.post_date),
            shipper_phone: `="${row.shipper_phone}"`,
            shipper_postal: `="${row.shipper_postal}"`,
            consignee_phone1: `="${row.consignee_phone1}"`,
            consignee_phone2: `="${row.consignee_phone2}"`,
            consignee_postal: `="${row.consignee_postal}"`,
          }))
        };
      } else {
        return {data: [], totalCount: 0};
      }
    },
    name: 'admin-list',
    label: 'Admin List',
    sortCol: 'id',
    sortDesc: true,
    pageRows: 20,
    addLabel: '',
    editLabel: '',
    categoryLabel: '',
    menuLabel: '',
    // actions: ['edit', 'print'],
    // actionWidth: 100,
    columns,
    useExternalForm: true,
    showDateSearch: true,
    sortableColumns: getSortableColumns(isVendor, columns),
    gridRef,
    // agGridProps: {
    //   rowSelection: isVendor ? 'multiple' : undefined,
    //   rowMultiSelectWithClick: isVendor ? true : undefined,
    // }
  });
}

function getSortableColumns(isVendor: boolean, columns: ColDef[]) {
  if (isVendor) {
    const sortableCols = ['post_date', 'weight', 'ctns', 'total_price', 'shipper', 'consignee'];
    const cols = {};
    for (const colDef of columns) {
      if(!sortableCols.includes(colDef.field)) {
        cols[colDef.field] = false;
      }
    }
    return cols;
  }
  return {};
}

export default AdminHistoryList;
