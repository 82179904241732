import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import airUrl from '../media/dz_air.jpg';
import oceanUrl from '../media/dz_maplebox.jpg';
import upsUrl from '../media/dz_ups.jpg';

export default function useConsentModal(buttonLabel, onAgree) {
  const [serviceType, setServiceType] = React.useState();
  const [destination, setDestination] = React.useState();
  function getUrl() {
    switch (serviceType) {
      case 'MA':
        return airUrl;
      case 'MO':
        return oceanUrl;
      case 'CP':
      case 'UPS':
        return upsUrl;
      default:
        return airUrl;
    }
  }
  const modal = useSimpleModal({
    title: 'Consent',
    width: 'calc(100vw - 2em)',
    centered: true,
    buttons:[
      {label: buttonLabel, onClick: onAgree, size: 'lg', color: 'primary'},
    ],
    children: (
      <div className={'mb-4'}>
        <img src={getUrl()} alt={'Consent'}/>
        {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Viverra nibh cras pulvinar mattis nunc sed. Habitant morbi tristique senectus et netus et malesuada fames ac. Vitae elementum curabitur vitae nunc sed. Nunc congue nisi vitae suscipit tellus mauris a. Pellentesque habitant morbi tristique senectus et. Urna nunc id cursus metus aliquam. Neque gravida in fermentum et sollicitudin ac orci phasellus egestas. Volutpat blandit aliquam etiam erat velit scelerisque in. Egestas quis ipsum suspendisse ultrices gravida dictum fusce ut placerat. Libero justo laoreet sit amet cursus. Viverra mauris in aliquam sem fringilla. Non odio euismod lacinia at quis risus sed vulputate. In arcu cursus euismod quis. Posuere urna nec tincidunt praesent semper feugiat nibh sed. Venenatis a condimentum vitae sapien pellentesque habitant morbi tristique senectus. Cras ornare arcu dui vivamus arcu felis bibendum. Id venenatis a condimentum vitae.</p>*/}
        {/*<p>Amet est placerat in egestas. Fermentum et sollicitudin ac orci phasellus egestas. Dolor sit amet consectetur adipiscing elit pellentesque. Nisl vel pretium lectus quam id leo in vitae. Quam nulla porttitor massa id neque aliquam vestibulum morbi. Egestas fringilla phasellus faucibus scelerisque. Tincidunt nunc pulvinar sapien et. In eu mi bibendum neque. Faucibus vitae aliquet nec ullamcorper sit amet risus. Ullamcorper dignissim cras tincidunt lobortis. Aliquam purus sit amet luctus venenatis lectus magna.</p>*/}
        {/*<p>Augue ut lectus arcu bibendum at varius. Augue mauris augue neque gravida. Ultricies tristique nulla aliquet enim tortor at auctor. Pellentesque habitant morbi tristique senectus et netus et. Libero id faucibus nisl tincidunt eget nullam non. Sed adipiscing diam donec adipiscing tristique risus nec. Dictum at tempor commodo ullamcorper a lacus vestibulum. Pulvinar sapien et ligula ullamcorper malesuada proin. Gravida quis blandit turpis cursus in hac habitasse platea. Condimentum id venenatis a condimentum. Diam in arcu cursus euismod quis. Ultrices eros in cursus turpis. Nunc sed velit dignissim sodales ut. Neque viverra justo nec ultrices dui sapien eget mi proin. Lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt.</p>*/}
      </div>
    ),
  });
  return {
    ...modal,
    open: (value, destination) => {
      setServiceType(value);
      setDestination(destination);
      modal.open();
    },
  };
}
