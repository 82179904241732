import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import images from '../../media/images';
import {useUser} from '../../redux/reducers/userReducer';
import {api, util} from '../../services/service';
import {DefaultButton} from '../../components/buttons';
import {basePathRegex, dropzoneIdRegex} from './ServiceSelector';

const PageContainer = ({pageContentStyle, children}) => {
  const location = useLocation();
  const {pathname} = location;
  const basePath = location.pathname.match(basePathRegex)?.[0] ?? '/';
  const dropzoneId = basePath.match(dropzoneIdRegex)?.[1];
  const user = useUser();
  const labels = {
    coshipLabel: 'Coship',
    inputLabel: 'Form',
    listLabel: 'List',
    printLabel: 'Print',
    footerCopyright: '© Canada Orient Shipping. All rights reserved.',
  };
  const homePath = '/';
  const selectPath = `/tablet/${dropzoneId}/select`;
  const copyright = labels.footerCopyright.replace('©', '© ' + new Date().getFullYear());
  const onTablet = async () => {
    // util.openTab(`${user.customer_url}/select`);
    await api.logout(`${user.customer_url}/select`);
  };
  const isTablet = pathname.startsWith('/tablet');
  return (
    <div className={'page-container'}>
      <div className={'page-header-container'}>
        <div className={'header-logo'}>
          {
            isTablet
              ? <Link to={selectPath}><img src={images.logo} alt={labels.coshipLabel} /></Link>
              : <Link to={homePath}><img src={images.logo} alt={labels.coshipLabel} /></Link>
          }
        </div>
        {user.id > 0 && (
          <div className={'header-menu'}>
            <span className={'mr-4'}>{user.eng_name}</span>
            {user.partner_type === 'DZ' && <DefaultButton label={'TABLET'} onClick={onTablet} noMargin />}
            <div className={'ml-8'} />
            <DefaultButton label={'LOGOUT'} onClick={() => api.logout()} noMargin />
          </div>
        )}
        {user.id > 0 && (
          <div className={'header-menu-mobile'}>
            <span className={'mr-4'}>{user.eng_name}</span>
            <DefaultButton label={'LOGOUT'} onClick={() => api.logout()} noMargin />
          </div>
        )}
      </div>
      <div className={'page-content-container'} style={pageContentStyle}>{children}</div>
      <div className={'page-footer-container'}>
        <img className={'footer-logo'} src={images.logoFooter} alt={labels.coshipLabel} />
        <span className={'footer-label'}>{copyright}</span>
      </div>
    </div>
  );
};

export default PageContainer;
