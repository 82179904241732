/**
 * @flow
 */
import React from 'react';
import PageContainer from './PageContainer';
import {Container} from 'reactstrap';

const NotFound = () => {
  return (
    <PageContainer>
      <Container>
        <div className={'flex center mt-40'}>
          <h4>404 Page Not Found</h4>
        </div>
      </Container>
    </PageContainer>
  );
};

export default NotFound;
