/**
 * @flow
 */
import React from 'react';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import MainWrapper from './MainWrapper';
import {util, api} from '../../services/service';
import AdminList from '../Home/AdminList';
import NotFound from '../Home/NotFound';
import ServiceSelector from '../Home/ServiceSelector';
import CustomerForm from '../Home/CustomerForm';
import Login from '../Home/Login';
import {updateUser} from '../../redux/reducers/userReducer';
import {useDispatch} from 'react-redux';
import AdminForm from '../Home/AdminForm';
import AdminHistoryList from '../Home/AdminHistoryList';

const BASE_PATH = '';

const Router = () => {
  const history = useHistory();
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  util.history = history;
  api.history = history;
  React.useEffect(() => {
    const paths = ['/', '/form'];
    if (paths.includes(pathname)) {
      api.queryUser().then(res => {
        updateUser(dispatch, res);
      });
    }
  }, []);
  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route path={`${BASE_PATH}/tablet/:id/select`} component={ServiceSelector} exact />
          <Route path={`${BASE_PATH}/tablet/:id/form`} component={CustomerForm} exact />
          <Route path={`${BASE_PATH}/login`} component={Login} exact />
          <Route path={`${BASE_PATH}/form`} component={AdminForm} exact />
          <Route path={`${BASE_PATH}/history`} component={AdminHistoryList} exact />
          <Route path={'/'} component={AdminList} exact />
          <Route path={'*'} component={NotFound} />
        </Switch>
      </main>
    </MainWrapper>
  );
};

export default Router;
