/**
 * @flow
 */
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Col, Container, Row} from 'reactstrap';
import LoginForm from '../../forms/LoginForm';
import PageContainer from './PageContainer';
import {api} from '../../services/service';

const loginForm = new LoginForm();

const loginBoxStyle = {
  borderRadius: 8,
  boxShadow: '0 10px 30px 1px rgba(0, 0, 0, 0.06)',
};
const Login = () => {
  const history = useHistory();
  loginForm.onLogin = async () => {
    const {email, password, trust} = loginForm.getValues();
    const res = await api.login({
      loginId: email,
      password,
      trustDevice: trust?.[0] === 'on',
    });
    if (res) {
      history.replace('/');
    }
  };
  return (
    <PageContainer>
      <Container className={'mt-2'}>
        <Row className={'p-4'}>
          <Col className={'pt-4'} sm={12} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}} style={loginBoxStyle}>
            <h3 className={'mb-4'}>LOGIN</h3>
            {loginForm.renderForm()}
          </Col>
        </Row>
      </Container>
    </PageContainer>
  );
};

export default Login;
