/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';
import {util} from '../services/service';
import images from '../media/images';
import koreaPostLogo from '../media/Korea_Post_Logo-700x331.png';
import coshipLogo from '../media/logo@2x.png';

class ManifestPrint extends React.Component {
  render() {
    const {data = {}} = this.props;
    if (Array.isArray(data)) {
      return (
        <div>
          {data.map((datum, index) => {
            const {ctns = 0} = datum;
            if (ctns <= 0) {
              return 0;
            }
            const items = [];
            for (let i = 0; i < ctns; ++i) items.push(datum);
            return (
              <div key={index}>
                {items.map((item, itemIndex) => this.renderItem(item, itemIndex))}
              </div>
            );
          })}
        </div>
      );
    } else {
      const {ctns = 0} = data;
      if (ctns <= 0) {
        return 0;
      }
      const items = [];
      for (let i = 0; i < ctns; ++i) items.push(data);
      return (
        <div>
          {items.map((item, index) => this.renderItem(item, index))}
        </div>
      );
    }
  }
  renderItem(item, index) {
    const {isLabelPrint} = this.props;
    const {
      post_date,
      port_type,
      bl_no,
      weight,
      ctns,
      price,
      shipper,
      shipper_phone,
      shipper_postal,
      shipper_address,
      consignee,
      consignee_phone1,
      consignee_phone2,
      consignee_postal,
      consignee_address,
      import_no,
      hs_code,
      memo,
      items,
      korea_post,
      is_air = port_type === 'A',
      cal_weight,
    } = item;
    const title = 'COSHIP';
    const sendCompany = is_air ? '발송 : HD SHIPPING' : '';
    const deliveryCompany = is_air ? '배송 : (주) 에스더쉬핑': '';
    const itemsCount = items.length;
    let itemsToDisplay = items.filter((i, index) => index < 3).map(i => i.item_qty > 1 ? `${i.item}(${i.item_qty})` : i.item).join(', ');
    const MAX_DESC_LEN = 50;
    if (itemsToDisplay.length > MAX_DESC_LEN) {
      itemsToDisplay = itemsToDisplay.substr(0, MAX_DESC_LEN) + '...';
    }
    const description = itemsToDisplay + (itemsCount > 3 ? ` 외 ${itemsCount - 3}건` : '') + ` / ${weight}KG`;
    const isSmall = cal_weight ? util.toFloat(cal_weight) <= 1 : weight ? util.toFloat(weight) <= 1 : false;
    if (isLabelPrint) {
      const style = {
        // width: '105mm',
        // height: '148mm',
        margin: '0 1cm',
        color: 'black',
        fontSize: 30,
      };
      return (
        <div className={'flex-column page-break air-manifest-print-container-lp'} style={style} key={`${index}`}>
          <div className={'flex mb-1'} style={{position: 'relative'}}>
            <div style={{flex: 0.6}}>
              <div className={'flex middle mb-1'}>
                <img src={koreaPostLogo} style={{width: '2.5cm'}} alt={'Korea Post'}/>
                <b className={'ml-4 fb-40'} style={{color: 'black'}}>우체국택배</b>
              </div>
              {/*<div className={'flex'}>*/}
              {/*  <div className={'flex-1 '}>MAWB NO.</div>*/}
              {/*  <div className={'flex-2 '}>{mbl_no}</div>*/}
              {/*</div>*/}
              {/*<div className={'flex'}>*/}
              {/*  <div className={'flex-1 '}>DATE</div>*/}
              {/*  <div className={'flex-2 '}>{out_date}</div>*/}
              {/*</div>*/}
            </div>
            <div className={'flex-1'}>
              <div className={'mt-1'}>접수국: 국제우편물류센터</div>
            </div>
            <div style={{position: 'absolute', right: 0, top: 0}}>
              <img src={coshipLogo} style={{width: '5.5cm'}} alt={'COSHIP'} />
            </div>
          </div>
          {/*<div className={'flex'}>*/}
          {/*  <div className={'flex-1 '}>MAWB NO.</div>*/}
          {/*  <div className={'flex-3 '}>{mbl_no}</div>*/}
          {/*  <div className={'flex-2 '} />*/}
          {/*</div>*/}
          <div className={'flex mb-1'}>
            <div className={'flex-1 '}>
              SHPR<br />(보내는 분)
            </div>
            <div className={'flex-3'} style={{fontSize: 35}}>
              <div>{shipper}</div>
              <div>{shipper_address}</div>
              <div>{shipper_phone}</div>
            </div>
            <div className={'flex-2'} style={{textAlign: 'center'}}>
              <Barcode value={consignee_postal} displayValue={false} height={150} width={4} />
              <div>우편번호 : {consignee_postal}</div>
            </div>
          </div>
          <div className={'flex mb-1'}>
            <div className={'flex-1 '}>
              CNNE<br />(받는 분)
            </div>
            <div style={{flex: 5, fontSize: 35}}>
              <div className={'fb-40'}>{consignee}</div>
              <div>{consignee_address}</div>
              <div>{consignee_phone1}{consignee_phone2 ? `/${consignee_phone2}`:''}</div>
            </div>
            {/*<div className={'flex-2'} style={{textAlign: 'center'}}>*/}
            {/*  /!*<Barcode value={postal} displayValue={false} height={150} width={4} />*!/*/}
            {/*  /!*<div>우편번호 : {postal}</div>*!/*/}
            {/*</div>*/}
          </div>
          <div className={'flex between middle mt-20 mb-20'}>
            <QRCode
              value={bl_no}
              size={120}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={false}
              renderAs={"svg"}
            />
            <div className={'flex-1 flex right middle'}>
              <div className={'fb-40'}>{korea_post[0]}</div>
              <div className={'fb-18 ml-3'} style={{paddingTop: 30}}>{korea_post[1]}</div>
              <div className={'fb-40 ml-3'}>{korea_post[2]}</div>
              <div className={'fb-18 ml-3'} style={{paddingTop: 30}}>{korea_post[3]}</div>
              {!isSmall && (
                <>
                  <div className={'fb-40 ml-3'}>{korea_post[4]}</div>
                  <div className={'fb-18 ml-2'} style={{paddingTop: 30}}>{korea_post[6] ? `- ${korea_post[6]} -` : ''}</div>
                  <div className={'fb-40 ml-2'}>{korea_post[5]}</div>
                </>
              )}
              {isSmall && (
                <div className={'ml-3'}>
                  <div className={'flex'} style={{backgroundColor: 'black', width: 90, height: 35, justifyContent: 'center', alignItems: 'center'}}>
                    <div className={'fb-30'} style={{color: 'white'}}>{korea_post[4]}</div>
                    <div className={'fb-30 ml-2'} style={{color: 'white'}}>{korea_post[5]}</div>
                  </div>
                  <div className={'fb-18'} style={{marginTop: -3, textAlign: 'center'}}>{korea_post[6] ? `- ${korea_post[6]} -` : ''}</div>
                </div>
              )}
            </div>
          </div>
          <div className={'mb-20 p-2'} style={{border: '2px solid black', minHeight: '4cm'}}>
            <div>배송메시지 :</div>
            <div>{memo}</div>
          </div>
          <div className={'flex-column center mb-20'}>
            <Barcode value={bl_no} displayValue={false} height={150} width={4} />
            <div>Tracking NO : {bl_no}</div>
          </div>
          <div className={'mb-1'}>
            <div>DESCRIPTION</div>
            <div>{description}</div>
          </div>
          {/*<div className={'flex'}>*/}
          {/*  <div className={'flex-1 '}>LOADING NO</div>*/}
          {/*  <div className={'flex-5 '}>HDSH2004071349</div>*/}
          {/*</div>*/}
          <div className={'flex mb-40'}>
            <div className={'flex-1 '}>주문번호</div>
            <div className={'flex-5 '}>{util.formatD(post_date).replaceAll('-', '')}-{index + 1}</div>
          </div>
          <div className={'flex-column center'}>
            <div>{index + 1} - {ctns}</div>
            <div>자가사용 목적 통관물품 상용판매시 처벌 받을 수 있음.</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={'flex-column page-break air-manifest-print-container'} key={index}>
          <div className={'flex mb-1'}>
            <div style={{flex: 1.3}}>
              <div className={'flex middle mb-4'}>
                {is_air && <img src={koreaPostLogo} style={{width: '2cm'}} alt={'Korea Post'}/>}
                {is_air && <b className={'ml-4 fb-20'} style={{color: 'black'}}>우체국택배</b>}
                {!is_air && <img src={images.logo} style={{width: '3cm'}} alt={'COSHIP'}/>}
                {/*/!*<b className={'ml-4 '} style={{color: 'black'}}>{title}</b>*!/*/}
              </div>
              {/*<div className={'flex'}>*/}
              {/*  <div className={'flex-1 '}>DATE</div>*/}
              {/*  <div className={'flex-2 '}>{util.formatD(post_date)}</div>*/}
              {/*</div>*/}
            </div>
            {is_air && (
              <div className={'mb-4'} style={{flex: 1.3}}>
                <div className={'mt-1'}>접수국: 국제우편물류센터</div>
              </div>
            )}
            {is_air && (
              <div className={'flex-1'}>
                <img src={images.logo} style={{width: '3cm'}} alt={'COSHIP'}/>
              </div>
            )}
          </div>
          <div className={'flex mb-1'}>
            <div className={'flex-1 '}>
              SHPR<br />(보내는 분)
            </div>
            <div className={'flex-3'}>
              <div>{shipper}</div>
              <div>{shipper_address}</div>
              <div>{shipper_phone}</div>
            </div>
            <div className={'flex-2'} style={{textAlign: 'center'}}>
              <Barcode value={consignee_postal} displayValue={false} height={50}/>
              <div>우편번호 : {consignee_postal}</div>
            </div>
          </div>
          <div className={'flex mb-1'}>
            <div className={'flex-1 '}>
              CNNE<br />(받는 분)
            </div>
            <div style={{flex: 5}}>
              <div className={'fb-20'}>{consignee}</div>
              <div>{consignee_address}</div>
              <div>{consignee_phone1}{consignee_phone2?`/${consignee_phone2}`:''}</div>
            </div>
            {/*<div className={'flex-2'} style={{textAlign: 'center'}}>*/}
            {/*  <Barcode value={consignee_postal} displayValue={false} height={50}/>*/}
            {/*  <div>우편번호 : {consignee_postal}</div>*/}
            {/*</div>*/}
          </div>
          <div className={'flex between middle mt-20 mb-20'}>
            <QRCode
              value={bl_no}
              size={80}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={false}
              renderAs={"svg"}
            />
            <div className={'flex-1 flex right middle'}>
              <div className={'fb-40'}>{korea_post[0]??'B5'}</div>
              <div className={'fb-18 ml-3'} style={{paddingTop: 30}}>{korea_post[1]??'성남M'}</div>
              <div className={'fb-40 ml-3'}>{korea_post[2]??'462'}</div>
              <div className={'fb-18 ml-3'} style={{paddingTop: 30}}>{korea_post[3]??'성남분당'}</div>
              {!isSmall && (
                <>
                  <div className={'fb-40 ml-3'}>{korea_post[4]}</div>
                  <div className={'fb-18 ml-2'} style={{paddingTop: 30}}>{korea_post[6] ? `- ${korea_post[6]} -` : ''}</div>
                  <div className={'fb-40 ml-2'}>{korea_post[5]}</div>
                </>
              )}
              {isSmall && (
                <div className={'ml-3'}>
                  <div className={'flex'} style={{backgroundColor: 'black', width: 90, height: 35, justifyContent: 'center', alignItems: 'center'}}>
                    <div className={'fb-30'} style={{color: 'white'}}>{korea_post[4]}</div>
                    <div className={'fb-30 ml-2'} style={{color: 'white'}}>{korea_post[5]}</div>
                  </div>
                  <div className={'fb-18'} style={{marginTop: -3, textAlign: 'center'}}>{korea_post[6] ? `- ${korea_post[6]} -` : ''}</div>
                </div>
              )}
            </div>
          </div>
          <div className={'mb-20 p-2'} style={{border: '2px solid black', minHeight: '4em'}}>
            <div>배송메시지 :</div>
            <div>{memo}</div>
          </div>
          <div className={'flex-column center mb-20'}>
            <Barcode value={bl_no} displayValue={false} height={50} />
            <div>Tracking NO : {bl_no}</div>
          </div>
          {is_air && (
            <div className={'mb-1'}>
              <div>DESCRIPTION</div>
              <div>{description}</div>
            </div>
          )}
          <div className={'flex mb-40'}>
            <div className={'flex-1 '}>주문번호</div>
            <div className={'flex-5 '}>{util.formatD(post_date).replaceAll('-', '')}-{index + 1}</div>
          </div>
          <div className={'flex-column center'}>
            {is_air && <div>{index + 1} - {ctns}</div>}
            {!is_air && <div style={{fontSize: 25, fontWeight: 'bold'}}>{index + 1} - {ctns}</div>}
            <div>자가사용 목적 통관물품 상용판매시 처벌 받을 수 있음.</div>
          </div>
        </div>
      );
    }
  }
}

export function useManifestPreviewModal(isLabelPrint: boolean = false) {
  const [data, setData] = React.useState([]);
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body-no-margin',
  });
  const modal = useSimpleModal({
    title: 'LABEL PRINT',
    width: isLabelPrint ? 1024 : 640,
    children: <ManifestPrint ref={printRef} data={data} isLabelPrint={isLabelPrint} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      print();
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (data) => {
      setData(data);
      modal.open();
    },
    print,
  };
}
