/**
 * @flow
 */
import React from 'react';
import PageContainer from './PageContainer';
import CustomerInputForm from '../../forms/CustomerInputForm';
import {useUser} from '../../redux/reducers/userReducer';
import {api, util} from '../../services/service';

const adminInputForm = new CustomerInputForm(false);

const AdminForm = () => {
  const user = useUser();
  adminInputForm.dropzoneId = undefined;
  adminInputForm.onSave = async (data) => {
    const {id} = util.getQS();
    const res = await api.saveBLData(undefined, {...data, blId: id, blNo: data.jcommon.blNo});
    if (res) {
      util.showSuccess('Request has been saved successfully!');
      if (id) {
        onQuery(id);
      } else {
        initForm(user);
      }
      util.history.goBack();
    }
  };
  const onQuery = (id) => {
    const DEF_SERVICE_TYPE = 'MA';
    const DEF_DESTINATION = 'KOREA';
    api.getBLData(id).then(({data}) => {
      if (data?.length === 1) {
        adminInputForm.setValuesFromServer(data[0]);
        const serviceType = adminInputForm.getValue('serviceType');
        const destination = adminInputForm.getValue('destination');
        if (!serviceType) {
          adminInputForm.setValue('serviceType', DEF_SERVICE_TYPE);
        }
        if (!destination) {
          adminInputForm.setValue('destination', DEF_DESTINATION);
        }
        const pickupType = adminInputForm.getValue('pickupType');
        const pickupDate = adminInputForm.getValue('pickupDate');
        const pickupTime = adminInputForm.getValue('pickupTime');
        if (pickupType === 'DROP OFF') {
          if (!(pickupDate && pickupTime)) {
            const {partner_id, pickup_day, pickup_time} = user;
            const info = util.getPickupDate(partner_id, pickup_day, pickup_time);
            if (info) {
              adminInputForm.setValue('pickupDate', util.formatD(info.pickupDate));
              adminInputForm.setValue('pickupTime', info.pickupTime);
            }
          }
        }
      }
    });
  };
  React.useEffect(() => {
    if (user.id > 0) {
      const {id} = util.getQS();
      if (id) {
        onQuery(id);
      } else {
        initForm(user);
      }
    }
  }, [user.id]);
  return (
    <PageContainer>
      {user.id > 0 && adminInputForm.renderForm()}
    </PageContainer>
  );
};

function initForm(user) {
  console.log('user.partner_type', user.partner_type);
  const DEF_SERVICE_TYPE = 'MA';
  if (user.partner_type === 'VD') {
    const {address, city, country, name_eng, name_kor, phone, postal_code, province} = user.departure;
    adminInputForm.setValuesFast({
      fromAddr: address,
      fromCity: city,
      fromProvince: province,
      fromCountry: country ? country : 'CANADA',
      fromPostal: postal_code,
      fromNameKor: name_kor,
      fromNameEng: name_eng,
      fromPhone: phone,
      destination: user.destination ? user.destination : 'KOREA',
      serviceType: DEF_SERVICE_TYPE,
    });
  }
  if (user.partner_type === 'DZ') {
    const {country} = user.departure;
    adminInputForm.setValuesFast({
      fromCountry: country ? country : 'CANADA',
      destination: user.destination ? user.destination : 'KOREA',
      serviceType: DEF_SERVICE_TYPE,
      packages: 1,
    });
  }
}
export default AdminForm;
