/**
 * @flow
 */
import React from 'react';
import PageContainer from './PageContainer';
import {useDataGridView} from '../../components/DataGridView';
import {useUser} from '../../redux/reducers/userReducer';
import {api, pathNames, util} from '../../services/service';
import {useDropzone} from 'react-dropzone';
import UploadIcon from 'mdi-react/UploadIcon';
import {useSimpleModal} from '../../components/SimpleModal';
import {DefaultButton} from '../../components/buttons';
import {Container} from 'reactstrap';
import {useManifestPreviewModal} from '../../print-layouts/ManifestPrint';
import {useManifestPreviewModal2} from '../../print-layouts/ManifestPrint2';
import {useManifestPreviewModal3} from '../../print-layouts/ManifestPrint3';
import {useAsyncStatusModal} from '../../components/AsyncStatusModal';
import {ColDef} from 'ag-grid-community';
// import {useParams} from 'react-router-dom';

const AdminList = () => {
  const gridRef = React.useRef();
  const printModalRef = React.useRef();
  const printModal2Ref = React.useRef();
  const printModal3Ref = React.useRef();
  const user = useUser();
  const printModal = useManifestPreviewModal(user.label_print === true);
  const printModal2 = useManifestPreviewModal2();
  const printModal3 = useManifestPreviewModal3();
  printModalRef.current = printModal;
  printModal2Ref.current = printModal2;
  printModal3Ref.current = printModal3;
  const grid = useGrid(user, gridRef, user.label_type === 'LABEL3' ? printModal3Ref : (user.label_type === 'LABEL2' ? printModal2Ref : printModalRef));
  const uploadModal = useCSVUploadModal((res) => {
    const message = res?.data?.[0]?.msg;
    if (message) {
      util.showSuccess(message);
      grid.query();
    }
    uploadModal.close();
  });
  const onNew = () => {
    util.nav(pathNames.FORM);
  };
  const onDownload = () => {
    const api = gridRef.current;
    api.exportDataAsCsv({
      fileName: `manifest-${util.formatD(Date.now())}.csv`,
      columnKeys: grid.props.columns.filter(c => c.headerName !== 'Actions').map(c => c.field),
    });
  };
  const onHistory = () => {
    util.nav('/history');
  };
  const onPrint = () => {
    const grid = gridRef.current;
    const selectedRows = grid.getSelectedRows();
    if (selectedRows.length > 0) {
      if(user.label_type === 'LABEL3') {  // CJ 대한통운
        printModal3.open(selectedRows, selectedRows.length > 1 ? true : (selectedRows.length === 1 && selectedRows[0].ctns > 1));
      } else if(user.label_type === 'LABEL2') {
        printModal2.open(selectedRows, selectedRows.length > 1 ? true : (selectedRows.length === 1 && selectedRows[0].ctns > 1));
      } else {
        printModal.open(selectedRows);
      }
      api.setBLPrint(selectedRows.map(r => r['bl_no'].replaceAll('"', '').replaceAll('=', ''))).catch();
    } else {
      util.showWarning('Please select rows to print.');
    }
  };
  const onDelete = () => {
    util.showConfirm('Are you sure to delete?', async () => {
      const gridApi = gridRef.current;
      const selectedRows = gridApi.getSelectedRows();
      if (selectedRows.length > 0) {
        const res = await api.delVendorBL(selectedRows.map(i => i.bl_id));
        if (res) {
          grid.query();
        }
      } else {
        util.showWarning('Please select rows to delete.');
      }
    });
  };
  const onUpload = () => uploadModal.open();
  const isVendor = user.partner_type === 'VD';
  return (
    <PageContainer>
      <Container style={{position: 'relative'}}>
        {user.id > 0 && grid.render()}
        {user.id > 0 && (
          <div className={'flex'} style={{position: 'absolute', left: 20, top: 10}}>
            <DefaultButton label={'New'} onClick={onNew} noMargin />
            <div className={'ml-8'} />
            {/*{isVendor && <DefaultButton label={'History'} onClick={onHistory} noMargin />}*/}
            <DefaultButton label={'History'} onClick={onHistory} noMargin />
            <div className={'ml-8'} />
            {<DefaultButton label={'Export Excel'} onClick={onDownload} noMargin />}
            {isVendor && <div className={'ml-8'} />}
            {isVendor && <DefaultButton label={'Upload'} onClick={onUpload} noMargin />}
            {isVendor && <div className={'ml-8'} />}
            {isVendor && <DefaultButton label={'Print'} onClick={onPrint} noMargin />}
            {isVendor && <div className={'ml-8'} />}
            {isVendor && <DefaultButton label={'Delete'} onClick={onDelete} noMargin />}
          </div>
        )}
      </Container>
      {uploadModal.render()}
      {printModal.render()}
      {printModal2.render()}
      {printModal3.render()}
    </PageContainer>
  );
};

function useGrid(user, gridRef, printModalRef) {
  const isVendor = user.partner_type === 'VD';
  const columns = getColumns(isVendor);
  return useDataGridView({
    onAction: (action, data) => {
      if (action === 'edit') {
        util.nav(pathNames.FORM + `?id=${data['bl_id']}`);
      } else if (action === 'print') {
        if(user.label_type === 'LABEL3') {
          printModalRef.current.open([data], data.ctns > 1 );
        } else if(user.label_type === 'LABEL2') {
          printModalRef.current.open([data], data.ctns > 1 );
        } else {
          printModalRef.current.open(data);
        }
        api.setBLPrint([data['bl_no'].replaceAll('"', '').replaceAll('=', '')]).catch();
      }
    },
    onQuery: async (listParams) => {
      if (user.id > 0) {
        const {data, totalCount} = await api.getBLList({...listParams, partnerId: user.id});
        const rows = data.map(row => ({
          ...row,
          post_date: util.formatD(row.post_date),
        }));
        if (isVendor) {
          return {
            totalCount,
            data: rows, //rows.sort((l, r) => r.total_price - l.total_price),
          };
        } else {
          return {
            totalCount,
            data: rows,
          };
        }
      } else {
        return {data: [], totalCount: 0};
      }
    },
    name: 'admin-list',
    label: 'Admin List',
    sortCol: 'post_date',
    sortDesc: true,
    pageRows: 20,
    addLabel: '',
    editLabel: '',
    categoryLabel: '',
    menuLabel: '',
    actions: ['edit', 'print'],
    actionWidth: 70,
    columns,
    useExternalForm: true,
    showDateSearch: true,
    sortableColumns: getSortableColumns(isVendor, columns),
    gridRef,
    agGridProps: {
      rowSelection: isVendor ? 'multiple' : undefined,
      rowMultiSelectWithClick: isVendor ? true : undefined,
    }
  });
}

function getCellStyle({value}) {
  if (value >= 150) {
    return {backgroundColor: '#fdd', color: 'red'};
  }
}

export function getColumns(isVendor: boolean, isHistory: boolean = false) {
  if (isVendor) {
    const columns: ColDef[] = [
      // {field: 'check', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 50, minWidth: 50},
      {field: 'post_date', headerName: 'Date', width: 100},
      {field: 'port_type', headerName: 'Type.', width: 70, minWidth: 70, valueFormatter: ({value}) => value === 'A' ? 'AIR' : 'OCEAN'},
      {field: 'bl_no', headerName: 'B/L No.', width: 120},
      {field: 'weight', headerName: 'Weight/CBM', width: 100},
      {field: 'total_price', headerName: 'Value', width: 80, minWidth: 80, valueFormatter: util.currencyFormatter, cellStyle: getCellStyle},
      {field: 'ctns', headerName: 'Pkg.', width: 50, minWidth: 50},
      {field: 'price', headerName: 'Price', width: 80, minWidth: 80, valueFormatter: util.currencyFormatter},
      {field: 'shipper', headerName: 'Shipper', width: 120},
      {field: 'shipper_phone', headerName: 'Phone', width: 100, valueFormatter: postalTextFormatter},
      {field: 'shipper_postal', headerName: 'Postal Code', width: 100, valueFormatter: postalTextFormatter},
      {field: 'shipper_address', headerName: 'Address'},
      {field: 'consignee', headerName: 'Consignee', width: 120},
      {field: 'consignee_phone1', headerName: 'Phone1', width: 100, valueFormatter: postalTextFormatter},
      {field: 'consignee_phone2', headerName: 'Phone2', width: 100, valueFormatter: postalTextFormatter},
      {field: 'consignee_postal', headerName: 'Postal Code', width: 100, valueFormatter: postalTextFormatter},
      {field: 'consignee_address', headerName: 'Address'},
      {field: 'import_no', headerName: 'Import No.', width: 100},
      {field: 'hs_code', headerName: 'HS Code', width: 100},
    ];
    return isHistory ? columns : [{field: 'check', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 40, minWidth: 40}, ...columns];
  } else {
    return [
      {field: 'post_date', headerName: 'Date', width: 100},
      {field: 'port_type', headerName: 'Type.', width: 100, valueFormatter: ({value}) => value === 'A' ? 'AIR' : 'OCEAN'},
      {field: 'bl_no', headerName: 'B/L No.', width: 120},
      {field: 'weight', headerName: 'Weight/CBM', width: 120},
      {field: 'total_price', headerName: 'Value', width: 100, valueFormatter: util.currencyFormatter},
      {field: 'ctns', headerName: 'Packages', width: 70, minWidth: 70},
      {field: 'price', headerName: 'Price', width: 100, valueFormatter: util.currencyFormatter},
      {field: 'shipper', headerName: 'Shipper', width: 150},
      {field: 'shipper_phone', headerName: 'Phone', width: 120, valueFormatter: postalTextFormatter},
      {field: 'shipper_postal', headerName: 'Postal Code', width: 120, valueFormatter: postalTextFormatter},
      {field: 'shipper_address', headerName: 'Address'},
      {field: 'consignee', headerName: 'Consignee', width: 150},
      {field: 'consignee_phone1', headerName: 'Phone1', width: 120, valueFormatter: postalTextFormatter},
      {field: 'consignee_phone2', headerName: 'Phone2', width: 120, valueFormatter: postalTextFormatter},
      {field: 'consignee_postal', headerName: 'Postal Code', width: 120, valueFormatter: postalTextFormatter},
      {field: 'consignee_address', headerName: 'Address'},
      {field: 'import_no', headerName: 'Import No.', width: 100},
      {field: 'hs_code', headerName: 'HS Code', width: 100},
    ];
  }
}

function getSortableColumns(isVendor: boolean, columns: ColDef[]) {
  const sortableCols = ['consignee'];
  const cols = {};
  for (const colDef of columns) {
    if(!sortableCols.includes(colDef.field)) {
      cols[colDef.field] = false;
    }
  }
  return cols;
}

const VendorUpload = ({onUpload}) => {
  const asyncStatusModal = useAsyncStatusModal('Uploading... please wait...');
  const onDrop = React.useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        asyncStatusModal.open();
        api.uploadCSV(reader.result).then((res) => {
          asyncStatusModal.close();
          onUpload(res);
        });
      };
      reader.readAsDataURL(file);
    }
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: '.csv, .xlsx',
    maxFiles: 1,
    onDropRejected: () => {
      util.showWarning('Please select valid CSV/Excel file!');
    }
  });
  return (
    <div className={'flex center middle mb-20'} {...getRootProps()} style={{height: 350, border: '1px solid #d0d0d0'}}>
      <input {...getInputProps()} />
      <div style={{textAlign: 'center'}}>
        <UploadIcon size={56} color={'#c0c0c0'} />
        {isDragActive ? <p>Drop the CSV/Excel file here ...</p> : <p>Drag & drop CSV/Excel file here, or click to select file</p>}
      </div>
      {asyncStatusModal.render()}
    </div>
  );
};

function useCSVUploadModal(onUpload) {
  const modal = useSimpleModal({
    title: 'CSV/Excel Upload',
    width: 720,
    centered: true,
    buttons: [
      {label: 'Close', onClick: () => modal.close()}
    ],
    children: <VendorUpload onUpload={onUpload} />,
  });
  return modal;
}

function postalTextFormatter(p) {
  return p.value?.toString().replaceAll('"', '').replace('=', '');
}

export default AdminList;
