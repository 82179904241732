import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { hot } from 'react-hot-loader';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import {GlobalDialog} from '../../redux/reducers/globalDialogReducer';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <Router />
        </ScrollToTop>
        <GlobalDialog />
      </BrowserRouter>
    </Provider>
  );
};

// export default hot(module)(App);
export default App;
