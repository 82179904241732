/**
 * @flow
 */
import React from 'react';
import FormManager from '../lib/FormManager';
import {util} from '../services/service';
import {PrimaryButton} from '../components/buttons';

class ItemInputForm extends FormManager {
  onLogin;
  constructor() {
    super({
      prefix: 'login-form',
      fields,
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
        horizontal: false,
        onSubmit: () => this.onLogin(),
      },
    });
  }
  onRender = () => {
    const {renderField: _r} = this;
    return (
      <div className={'w-full'}>
        {_r(FN.EMAIL)}
        {_r(FN.PASSWORD)}
        {_r(FN.TRUST)}
        <div className={'flex center mb-4 pb-4'}>
          <PrimaryButton label={'LOGIN'} type={'submit'} />
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    // const {email, password} = values;
    // if (!email) {
    //   util.showError('Please input an Email.');
    //   return null;
    // }
    // if (!password) {
    //   util.showError('Please input a Password.');
    //   return null;
    // }
    // return values;
  };
}

const FN = {
  EMAIL: 'email',
  PASSWORD: 'password',
  TRUST: 'trust',
};

const LB = {
  EMAIL: 'User ID',
  PASSWORD: 'Password',
  TRUST: 'Remember',
};

const SN = FN;

const fields = [
  { name: FN.EMAIL, serverName: SN.EMAIL, label: LB.EMAIL, type: 'email', autocomplete: 'off', required: true, },
  { name: FN.PASSWORD, serverName: SN.PASSWORD, label: LB.PASSWORD, type: 'password', autocomplete: 'off', required: true, useSameAsInput: true },
  { name: FN.TRUST, serverName: SN.TRUST, label: LB.TRUST, type: 'checkbox', required: false, },
];

export default ItemInputForm;
