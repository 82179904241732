/**
 * @flow
 */
import React from 'react';
import moment from 'moment';
import type {DataGridInfo} from '../redux/reducers/dataGridReducer';
import queryString from 'query-string';
import {showDialog} from '../redux/reducers/globalDialogReducer';
import {util} from './service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

//const REGEX_THOUSAND_SEP = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;

const numberOnlyMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
})

const numberDecimalTwoMask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
  decimalLimit: 2,
})

class Util {
  getState;
  dispatch;
  history;
  gridInfoCache = {};
  MASK_DATE = [/[12]/, /\d/, /\d/, /\d/, '-' , /[01]/, /\d/, '-', /[0123]/, /\d/];
  MASK_TIME = [/\d/, /\d/, ':', /\d/, /\d/];
  MASK_PHONE;
  MASK_RATE = ['0', '.', /\d/, /\d/];
  MASK_NUMBER_ONLY = numberOnlyMask;
  MASK_NUMBER_DECIMAL_TWO = numberDecimalTwoMask;
  constructor() {
    const regexPhone = /[+\-\d]/;
    const maskPhone = [];
    for (let i = 0; i < 20; ++i) {
      maskPhone.push(regexPhone);
    }
    this.MASK_PHONE = maskPhone;
  }
  formatDate(ts, format = 'YYYY-MM-DD HH:mm:ss') {
    if (ts === 0) return '';
    return moment(ts).format(format);
  }
  formatD(ts) {
    return ts ? moment(ts).format('YYYY-MM-DD') : '';
  }
  formatDT(ts) {
    return ts ? moment(ts).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  formatFlightT(ts) {
    return ts ? moment(ts).format('HH:mm') : '';
  }
  formatYesNo(p) {
    return p.value === true ? 'Yes' : 'No';
  }
  formatCBM({value}) {
    if (typeof value === 'string') {
      value = parseFloat(value);
      if (isNaN(value)) {
        return '';
      }
    }
    // return value?.toFixed(2).replace(REGEX_THOUSAND_SEP, ',') ?? '';
    return value?.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) ?? '';
  }
  formatWeight({value}) {
    if (typeof value === 'string') {
      value = parseFloat(value);
      if (isNaN(value)) {
        return '';
      }
    }
    // return value?.toFixed(1).replace(REGEX_THOUSAND_SEP, ',') ?? '';
    return value?.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) ?? '';
  }
  dateFormatter(p) {
    return p.value ? moment(p.value).format('YYYY-MM-DD') : '';
  }
  dateTimeFormatter(p) {
    return p.value ? moment(p.value).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  dateTimeFlightFormatter(p) {
    return p.value ? moment(p.value).format('YYYY-MM-DD HH:mm') : '';
  }
  yesNoFormatter(p) {
    return p.value === true ? 'Yes' : 'No';
  }
  currencyFormatter(p) {
    // const str = p.value?.toString().replace(REGEX_THOUSAND_SEP, ',') ?? '';
    const value = parseFloat(p.value);
    if (isNaN(value)) return '';
    const str = value?.toLocaleString(undefined, {minimumFractionDigits: 2});
    if (!str) return '';
    if (str.indexOf('.') < 0) {
      return str + '.00';
    } else {
      return str;
    }
  }
  currencyFormatterEx(param, currencyFieldName) {
    // let value = param.value?.toString().replace(REGEX_THOUSAND_SEP, ',');
    // if (!value) return value;
    let value = parseFloat(param.value);
    if (isNaN(value)) return '';
    value = value.toLocaleString(undefined, {minimumFractionDigits: 2});
    const currency = param.data[currencyFieldName];
    if (value.indexOf('.') < 0 && currency !== 'KRW') { // 한화인 경우 소수점이하 두자리를 보여주지 않음
      value += '.00';
    }
    return currency ? `${currency} ${value}` : value;
  }
  formatCurrency(number: number) {
    return number?.toLocaleString(undefined, {minimumFractionDigits: 2}) ?? '';
    // return (number === undefined || number === null) ? number : number.toFixed(2).replace(REGEX_THOUSAND_SEP, ',');
  }
  destinationFormatter(param) {
    const {dcount = 0} = param.data;
    const {value} = param;
    if (!value) return '';
    return dcount > 1 ? `${param.value} (+${dcount - 1})` : param.value;
  }
  toTS(date) {
    if (date instanceof Date) return date.getTime();
    if (date === null || date === undefined) return date;
    return moment(date).toDate().getTime();
  }
  toInt(value: any) {
    return typeof value === 'string' ? parseInt(value) : value;
  }
  toFloat(value: any) {
    return typeof value === 'string' ? parseFloat(value) : value;
  }
  nav(path) {
    this.history.push(path);
  }
  openTab(path) {
    window.open(path, '_blank');
  }
  valuesFromListData(row, fields, names, dateFields: string[]) {
    const values = {};
    for(const field of fields) {
      const nameMap = names.find(([, name]) => field.name === name);
      const listName = nameMap[0];
      if (dateFields.includes(field.name)) {
        if (row[listName] === 0) {
          values[field.name] = '';
        } else {
          values[field.name] = this.formatDate(row[listName], 'YYYY-MM-DD');
        }
      } else {
        values[field.name] = row[listName];
      }
    }
    return values;
  }
  setGridInfo(name, info) {
    this.gridInfoCache[name] = {...this.gridInfoCache[name], ...info};
  }
  getGridInfo(name): DataGridInfo {
    return this.gridInfoCache[name];
  }
  showDialog(message, title = 'Success', color = 'success') {
    showDialog(message, title, color);
  }
  showSuccess(message) {
    showDialog(message, 'Success', 'success');
  }
  showWarning(message) {
    showDialog(message, 'Warning', 'warning');
  }
  showError(message = 'Something went wrong!') {
    showDialog(message, 'System Error', 'danger');
  }
  showConfirm(message, onConfirm) {
    showDialog(message, 'Confirm', 'primary', onConfirm);
  }
  getPaginationInfo(gridInfo: DataGridInfo) {
    const {page = 1, rowCount = 20, totalCount = 0} = gridInfo;
    if(totalCount === 0) {
      return null;
    }
    const startIndex = (page - 1) * rowCount;
    let endIndex = page * rowCount - 1;
    if(endIndex >= totalCount) endIndex = totalCount - 1;
    let totalPages;
    if(totalCount <= rowCount) {
      totalPages = 1;
    } else {
      if(rowCount % totalCount === 0) {
        totalPages = parseInt(totalCount / rowCount);
      } else {
        totalPages = parseInt(totalCount / rowCount) + 1;
      }
    }
    return {page, totalPages, totalCount, startIndex, endIndex};
  }
  noop() {}
  getBeginEndDateTS(date, mode: 'week' | 'month') {
    const startDate = moment(date).startOf(mode).set({hour:0,minute:0,second:0,millisecond:0}).toDate().getTime();
    const endDate = moment(date).endOf(mode).set({hour:0,minute:0,second:0,millisecond:0}).toDate().getTime();
    return [startDate, endDate];
  }
  canDeleteSchedule(ts: number) {
    // 하루전 스케줄만 삭제할수 있음!
    const day = moment(ts).format('YYYY-MM-DD');
    const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
    console.log(day, yesterday);
    return day >= yesterday;
  }
  renderFooter(footer) {
    const labels = [];
    const [c_ts = 0, c_name] = footer?.create ?? [];
    const [e_ts = 0, e_name] = footer?.edit ?? [];
    if (c_ts > 0) {
      labels.push(`Created by ${c_name} (${util.formatDate(c_ts)})`);
    }
    if (e_ts > 0) {
      labels.push(`Edited by ${e_name} (${util.formatDate(e_ts)})`);
    }
    if (labels.length === 1) {
      return (
        <div className={'moving-form-footer'}>
          {labels[0]}
        </div>
      );
    } else if (labels.length === 2) {
      return (
        <div className={'moving-form-footer'}>
          {labels[0]}
          <br />
          {labels[1]}
        </div>
      );
    } else {
      return null;
    }
  }
  isFormikChecked(value) {
    if (Array.isArray(value)) {
      return value?.[0] === 'on';
    } else {
      return value === true;
    }
  }
  workTypeMap = {P: 'O', D: 'I', R: 'R'};
  buildEvents(data: Object[]) {
    return data.map(item => {
      const {hbl, cbm, customer_name: name, job_city: city, job_date: date, job_time: time, work_type: workType, work_count: workCount} = item;
      const type = workType.length === 2 ? workType[1] : '';
      let title = `${this.workTypeMap[type]} ${cbm} ${name} ${time ? time : ''} ${city ? city : ''}`;
      if (workCount > 1) {
        title += `(${workCount})`;
      }
      return {
        title,
        start: new Date(date),
        end: new Date(date),
        allDay: true,
        resource: {hbl, cbm, name, city, date, time, workType, workCount},
      };
    });
  }
  logFields(FN, LB) {
    let output = '';
    for(const key of Object.keys(FN)) {
      output += `{name: FN.${key}, serverName: FN.${key}, label: '${LB?.[key] ?? ''}'},\n`;
    }
    console.log(output);
  }
  getQS() {
    return queryString.parse(document.location.search);
  }
  getPickupDate(partnerId, pickupDay, pickupTime) {
    // const dropzone = dropzoneOptions.find(i => i.value === partnerId);
    // const {pickupDay, pickupTime} = dropzone ?? {};
    if (pickupDay && pickupTime) {
      const daysStart = {
        Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6,
      };
      const daysInfo = [
        ['Sun', false, ''],
        ['Mon', false, ''],
        ['Tue', false, ''],
        ['Wed', false, ''],
        ['Thu', false, ''],
        ['Fri', false, ''],
        ['Sat', false, ''],
        ['Sun', false, ''],
        ['Mon', false, ''],
        ['Tue', false, ''],
        ['Wed', false, ''],
        ['Thu', false, ''],
        ['Fri', false, ''],
        ['Sat', false, ''],
      ];
      let startDate = moment().startOf('week');
      daysInfo.forEach((dayInfo, index) => {
        const [day, allowed] = dayInfo;
        if (pickupDay.find(d => d === day)) {
          daysInfo[index][1] = true;
        }
        daysInfo[index][2] = startDate.format('YYYY-MM-DD');
        startDate = startDate.add(1, 'day');
      });
      const todayDay = moment().format('ddd');
      const todayDate = moment().format('YYYY-MM-DD');
      const todayTime = moment().format('A');
      const todayIndex = daysStart[todayDay];
      let pickupDayInfo;
      for (let i = todayIndex; i < daysInfo.length; ++i) {
        const [day, allowed, date] = daysInfo[i];
        if (allowed) {
          if (date === todayDate) {
            if (pickupTime === 'AM' && todayTime === 'PM') {
              //console.log('hello same day', day, allowed, date, todayDate);
              pickupDayInfo = {pickupDate: moment(date).toDate(), pickupTime: todayTime};
              break;
            }
          } else {
            //console.log('hello diff day', day, allowed, date, todayDate);
            pickupDayInfo = {pickupDate: moment(date).toDate(), pickupTime: todayTime};
            break;
          }
        }
      }
      return pickupDayInfo;
    }
  }
  getCurrentDate2() {
    const m = moment();
    return m.format('YYYY.MM.DD');
  }
  textSubstring = (data, len) => {
		return data.length > len ? `${data.substr(0, len)}...` : data;
	}
}

export default Util;
